// Script which handles switching between
// portrait and landscape video sources

export function videoSrcSwitch() {
    const videos = document.querySelectorAll("video[data-multy-src]");
    const orientationMq = window.matchMedia("(orientation: landscape)");

    if (videos.length > 0) {
        for (const video of videos) {
            const portrait = video.getAttribute("data-portrait");
            const landscape = video.getAttribute("data-landscape");

            function handleDeviceOrientation(e) {
                if (e.matches) {
                    switchSrc(video, landscape);
                } else {
                    switchSrc(video, portrait);
                }
            }

            orientationMq.addEventListener("change", handleDeviceOrientation);

            handleDeviceOrientation(orientationMq);
        }

        function switchSrc(video, src) {
            video.setAttribute("src", src);
            video.load();
            video.play();
        }
    }
}