import Splide from "@splidejs/splide";

export function initCasesSlider() {
    const dmCases = document.querySelectorAll(".dm-cases");

    if (dmCases) {
        for (const dmCase of dmCases) {
            const imageSliderEl = dmCase.querySelector(".dm-cases__images");
            const textSliderEl = dmCase.querySelector(".dm-cases__text");

            const _autoplay = imageSliderEl.dataset.autoplay;
            const _interval = +imageSliderEl.dataset.autoplayInterval;

            const imageSlider = new Splide(imageSliderEl, {
                arrows: false,
                pagination: false,
                flickPower: 100,
                type: "loop",
                gap: "1rem",
                autoplay: _autoplay === "true" ? true : false,
                interval: _interval ? _interval : 5000,
                mediaQuery: "min",
                breakpoints: {
                    1148: {
                        arrows: true,
                        pagination: true
                    }
                }
            });

            const textSlider = new Splide(textSliderEl, {
                type: "fade",
                rewind: true,
                arrows: false,
                mediaQuery: "min",
                autoHeight: true,
                flickPower: 100,
                breakpoints: {
                    1148: {
                        pagination: false
                    }
                }
            });

            imageSlider.sync(textSlider);

            imageSlider.mount();
            textSlider.mount();
        }
    }
}