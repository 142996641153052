import { initAccordion } from "./accordion.mjs";

export function jobFilter() {
    const jobFilterComponent = document.querySelector(".dm-job-filter");

    if (jobFilterComponent) {
        const BASE_URL = contextPath + "/.rest/content-delivery";
        const language = document.documentElement.getAttribute("lang");

        const filterButtons = document.querySelectorAll(".dm-job-filter .dm-tag");

        let activeFilter = "";

        // Select filter name from first button
        let filterName = filterButtons[0].getAttribute("data-filter");

        // Set active class to first button
        filterButtons[0].classList.add("active");

        filterButtons.forEach(button => {
            button.addEventListener("click", () => {
                const siblings = button.closest(".tag-filter__scroll").querySelectorAll(".dm-tag");
                filterName = button.getAttribute("data-filter");

                if (filterName === activeFilter) return;

                siblings.forEach(btn => btn.classList.remove("active"));
                button.classList.add("active");

                fetchJobs();
            });
        });

        async function fetchJobs() {
            // Clear results
            jobFilterComponent.querySelector(".dm-accordion").innerHTML = "";

            // Create loading indicator
            const loadader = document.createElement("div");
            loadader.classList.add("loader");
            jobFilterComponent.querySelector(".dm-accordion").appendChild(loadader);

            // Fetch jobs data and populate accordion
            const response = await fetch(BASE_URL + `?lang=${language}&limit=6&offset=0&jobsTags%5Blike%5D=%25jobs%2F${filterName}%25&orderBy=teaserDate`);
            const data = await response.json();

            // Clear loading indicator or previous results
            jobFilterComponent.querySelector(".dm-accordion").innerHTML = "";

            if (data.results.length > 0) {
                for (const [index, job] of data.results.entries()) {
                    const jobElement = document.createElement("div");
                    jobElement.classList.add("dm-accordion__item");

                    jobElement.innerHTML = `
                        <div class="dm-accordion__head">
                            <button aria-expanded="${index === 0 ? true : false}" aria-controls="dmJobAccordion-${index}" data-toggle-accordion="">${job.title}
                                <svg width="13" height="13" viewBox="0 0 13 13" aria-hidden="true">
                                    <use href="${contextPath}/.resources/dm-com-light-module/webresources/assets/_icn/icn-accordion.svg#expanded" class="expanded"></use>
                                    <use href="${contextPath}/.resources/dm-com-light-module/webresources/assets/_icn/icn-accordion.svg#collapsed" class="collapsed"></use>
                                </svg>
                            </button>
                        </div>
                        <div class="dm-accordion__body" aria-hidden="${index === 0 ? false : true}" id="dmJobAccordion-${index}">
                            <div class="dm-accordion__content">
                                <div class="dm-accordion__content-inner">
                                    ${job.teaserCopy}
                                </div>
                            </div>
                        </div>
                    `;

                    jobFilterComponent.querySelector(".dm-accordion").appendChild(jobElement);
                }

                initAccordion();
            } else {
                const errorMsg = document.createElement("p");

                errorMsg.textContent = jobFilterComponent.querySelector(".dm-accordion").getAttribute("data-no-jobs");

                jobFilterComponent.querySelector(".dm-accordion").appendChild(errorMsg);
            }
        }

        fetchJobs();
    }
}