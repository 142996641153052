import Masonry from "masonry-layout";

export function filterContentDelivery() {
    const casesAndBlogGrid = document.querySelector(".dm-cases-blog-grid");
    const searchParams = new URLSearchParams(window.location.search);

    if (casesAndBlogGrid) {
        const BASE_URL = contextPath + "/.rest/content-delivery";
        const LIMIT = 6;
        let offset = 0;
        let totalNumberOfPosts = 0;
        let activeFilter = "";

        if (searchParams.has('filter')) {
            activeFilter = searchParams.get('filter');
        }

        const type = casesAndBlogGrid.getAttribute("data-type");
        const filterButtons = casesAndBlogGrid.querySelectorAll(".dm-tag");
        const clearFilterBtn = casesAndBlogGrid.querySelector(".dm-tag--clear-filter");
        const filterResultsBlog = casesAndBlogGrid.querySelector("#list-grid");
        const loadMore = casesAndBlogGrid.querySelector("#load-more-container");
        const language = document.documentElement.getAttribute("lang");
        const localePath = "/" + language;
        const gridResults = casesAndBlogGrid.querySelector(".dm-cases-blog-grid__list");

        let numberOfDisplayedResults = 0;

        const loader = document.createElement("div");
        loader.classList.add("loader");
        // filterResultsBlog.appendChild(loader);

        const debounce = (mainFunction, delay) => {
            // Declare a variable called 'timer' to store the timer ID
            let timer;

            // Return an anonymous function that takes in any number of arguments
            return function (...args) {
                // Clear the previous timer to prevent the execution of 'mainFunction'
                clearTimeout(timer);

                // Set a new timer that will execute 'mainFunction' after the specified delay
                timer = setTimeout(() => {
                    mainFunction(...args);
                }, delay);
            };
        };

        for (const filterBtn of filterButtons) {
            filterBtn.addEventListener("click", () => {
                window.removeEventListener("scroll", debounceInfiniteScoll);
                const filterName = filterBtn.getAttribute("data-filter");
                const siblings = filterBtn.closest(".tag-filter__scroll").querySelectorAll(".dm-tag");

                if (filterName === activeFilter) return;

                siblings.forEach(btn => btn.classList.remove("active"));
                filterBtn.classList.add("active");
                activeFilter = filterName;
                offset = 0;

                filterResultsBlog.innerHTML = "";
                filterResultsBlog.appendChild(loader);

                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('filter', activeFilter);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);

                filterResultsBlog.closest(".dm-container").style.height = filterResultsBlog.getBoundingClientRect().height + "px";

                handleClearFilterButton();

                filterContent(activeFilter);
                window.addEventListener("scroll", debounceInfiniteScoll);
            })
        }

        handleClearFilterButton();
        highlightSelectedFilterOnPageRefresh();

        function handleClearFilterButton() {
            // show or hide clear filter button and remove search params
            const url = window.location.search;
            const noFilters = window.location.href.split("=")[1];

            if (url && clearFilterBtn != undefined) {
                clearFilterBtn.style.display = "inline-flex";
            }

            if (noFilters === "" && clearFilterBtn != undefined) {
                clearFilterBtn.style.display = "none";

                const urlObj = new URL(window.location.href);
                urlObj.search = '';
                urlObj.hash = '';
                const result = urlObj.toString();

                window.history.pushState({ path: result }, '', result);
            }
        }

        function highlightSelectedFilterOnPageRefresh() {
            // fix for when in edit mode
            if (window.location.href.includes("mgnlPreview")) return;

            // Add 'active' class to filter button on page refresh if filter is active
            const activeFilter = window.location.href.split("=")[1];

            if (activeFilter !== "undefined") {
                const filterButton = document.querySelector(`[data-filter=${activeFilter}]`);

                if (filterButton) {
                    filterButton.classList.add("active");
                }
            }
        }

        function infiniteScoll() {
            if (gridResults.clientHeight + window.scrollY >= document.body.offsetHeight / 2) {

                loadMore.style.display = "block";
                if (numberOfDisplayedResults + 1 <= totalNumberOfPosts) {
                    offset += LIMIT;
                    filterContent(activeFilter);
                } else {
                    removeInfiniteScroll();
                    loadMore.style.display = "none";
                }
            }
        }

        function removeInfiniteScroll() {
            window.removeEventListener("scroll", debounceInfiniteScoll);
        }

        const debounceInfiniteScoll = debounce(infiniteScoll, 300);

        async function filterContent(filterName) {
            const masonryLayout = new Masonry(filterResultsBlog, {
                itemSelector: ".dm-card",
                percentPosition: true,
                gutter: 26
            });

            const response = await fetch(BASE_URL + `?lang=${language}&limit=${LIMIT}&offset=${offset}&${type}Tags%5Blike%5D=%25${type}%2F${filterName}%25&orderBy=teaserDate%20desc`);

            const data = await response.json();

            // console.log(data)
            totalNumberOfPosts = data.total;

            if (data.results.length > 0) {
                for (const [index, card] of data.results.entries()) {
                    const teaserCard = document.createElement("div");
                    const postDate = new Date(card.teaserDate);
                    const day = postDate.getDate();
                    const month = postDate.toLocaleString('default', { month: 'long' });
                    const year = postDate.getFullYear();
                    let path = card['@path'].replace("/home", "");

                    // teaserCard.classList.add("dm-card", card.teaserCardBackgroundColour ?? card.teaserCardBackgroundColour, "dm-teaser-card", card.teaserImageSize ?? card.teaserImageSize);

                    // console.log(index % 2 === 0)

                    teaserCard.classList.add("dm-card", card.teaserCardBackgroundColour ?? card.teaserCardBackgroundColour, "dm-teaser-card", index % 2 === 0 ? "dm-teaser-card--big-img" : "dm-teaser-card--default-img");

                    teaserCard.innerHTML = `
                        ${card.teaserImage ?
                            `<div class="dm-teaser-card__image">
                                <img src="${card.teaserImage['@link']}" alt="${card.teaserImage.teaserImageAltText}" width="395" height="246" loading="lazy">
                            </div>` :
                            ''
                        }
                        <div class="dm-teaser-card__text">
                            ${card.teaserTag ?
                            `<div class="dm-tag">
                                    ${card.teaserTag}
                                </div>` :
                            ''
                        }
                            ${card.teaserDate ?
                            `<div class="dm-date">
                                    <em>${month} ${day}, ${year}</em>
                                </div>` :
                            ''
                        }
                            ${card.teaserHeadline ?
                            `<div class="dm-card__headline">
                                    <h3>${card.teaserHeadline}</h3>
                                </div>` :
                            ''
                        }
                            ${card.teaserCopy ?
                            `<div class="dm-card__copy">
                                    <p>${card.teaserCopy}</p>
                                </div>` :
                            ''
                        }
                        ${card.teaserCardButtonText ?
                            `${card.teaserButtonLinkStyle ?
                                `<div class="dm-card__link">
                                    <a href="${contextPath}${localePath}${path}">${card.teaserCardButtonText}</a>
                                    <a href="${contextPath}${localePath}${path}" class="circle">
                                        <svg width="13" height="14" fill="none" viewBox="0 0 13 14" aria-hidden="true">
                                            <use href="${contextPath}/.resources/dm-com-light-module/webresources/assets/_icn/icn-circle-arrow.svg#icn"></use>
                                        </svg>
                                    </a>
                                </div>` :
                                `<a href="${contextPath}${localePath}${path}" class="dm-btn">
                                    ${card.teaserCardButtonText}
                                    ${card.teaserCardButtonArrow ?
                                    `<svg width="22" height="20" fill="none" viewBox="0 0 22 20" aria-hidden="true">
                                            <use href="${contextPath}/.resources/dm-com-light-module/webresources/assets/_icn/icn-btn-arrow.svg#icn"></use>
                                        </svg>` :
                                    ""
                                }
                                </a>`
                            }` :
                            ""}
                           
                        </div>`;

                    loader.remove();
                    filterResultsBlog.appendChild(teaserCard);
                    masonryLayout.appended(teaserCard);
                }

                loadMore.style.display = "none";
            } else {
                const noItemsFound = document.getElementById("no-content-msg");
                filterResultsBlog.innerHTML = `<h2 class='text-center' style='column-span: all;'>${noItemsFound ? noItemsFound.textContent : 'No items Found'}</h2>`;
            }

            numberOfDisplayedResults = filterResultsBlog.children.length;

            if (numberOfDisplayedResults >= totalNumberOfPosts) {
                loadMore.style.display = "none";
            }

            masonryLayout.layout();
            filterResultsBlog.closest(".dm-container").removeAttribute("style");
        }

        filterContent(activeFilter);

        window.addEventListener("scroll", debounceInfiniteScoll)
    }
}