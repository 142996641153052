import Splide from "@splidejs/splide";

export function initBlogWallSlider() {
    const blogWallSliders = document.querySelectorAll(".dm-blog-post-wall__slider");

    if(blogWallSliders.length > 0) {
        for(const blogWallSlider of blogWallSliders) {
            const slider = new Splide(blogWallSlider, {
                type: "loop",
                arrows: false,
                gap: "0.875rem",
                mediaQuery: "min",
                flickPower: 100,
                breakpoints: {
                    "768": {
                        perPage: 2
                    },
                    "1180": {
                        perPage: 3,
                        type: "slide"
                    }
                }
            }).mount();
    
            slider.on("resize", () => slider.refresh());
        }
    }
}